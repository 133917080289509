/**
 * mix in for handle loading
 */
 import { showErrorToast } from '@/utils/toast';

export default {
  data(){
    return{
      loaderState: false
    }
  },
  methods:{
    async init(args){
      try{
        const delayLoader = setTimeout(() => {
          this.loaderState = true;
        },1000)
        await Promise.all([
          ...args,
        ])
        clearTimeout(delayLoader);
        await new Promise((resolve) => setTimeout(() => {
          this.loaderState = false;
          resolve()
        }, 1000))
      }catch{
        showErrorToast(this.$swal, 'fetchError', { item: 'items' });
      }
    }
  }
}